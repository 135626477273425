import React, { FunctionComponent } from 'react'
import { LabelledToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import { SmartLink } from '../SmartLink'
import { TOAST_SHOP_LINK } from '../constants'
import { useTranslation } from '@local/translations'

type SettingsPayoutEnabledToggleProps = {
  isActive: boolean
  adminPayoutsLocked: boolean
  onClickHandlerPayoutEnabled: Function
}
export const SettingsPayoutEnabledToggle: FunctionComponent<
  SettingsPayoutEnabledToggleProps
> = ({ isActive, adminPayoutsLocked, onClickHandlerPayoutEnabled }) => {
  const { t, Trans } = useTranslation()
  return (
    <>
      <div className='flex flex-row justify-between'>
        <p className='font-semibold type-default text-default mt-3 md:mt-5'>
          {t('pay-out-panel.enable-toggle')}
        </p>
        <LabelledToggleSwitch
          isActive={isActive && !adminPayoutsLocked}
          disabled={adminPayoutsLocked}
          size='xxs'
          onChange={() => {
            onClickHandlerPayoutEnabled()
          }}
        />
      </div>
      <div>
        <p className='mt-4 mb-8 text-secondary md:mt-5 type-default md:type-default'>
          <Trans
            i18nKey='pay-out-panel.description'
            components={{
              superscript: <sup />,
              learnMoreLink: (
                <SmartLink
                  className={'text-secondary font-semibold underline'}
                  pathname={TOAST_SHOP_LINK}
                  isExternal={true}
                  isNewTab={true}
                >
                  Learn more about PayOuts
                </SmartLink>
              )
            }}
          />
        </p>
      </div>
    </>
  )
}
